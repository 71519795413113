import React, { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import './HomeContent.css'
import { FileTextOutlined, GlobalOutlined, PictureOutlined, FileExcelOutlined, CoffeeOutlined, MessageOutlined} from '@ant-design/icons'

interface ChatGPTProps {
  themeClassName: string;
}

const HomeContent: React.FC<ChatGPTProps> = ({ themeClassName }) => {
  let buttonType: "default" | "primary" = "primary";

  // const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [sizeChar, setSizeChar] = useState({ sizeHeader: 0, sizeWord: 0, sizeImage: 0, sizeMargin: 0, sizeButton:0, gap: ""});

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setSizeChar({ sizeHeader: 9, sizeWord:  7, sizeImage: 1.0, sizeMargin: 10, sizeButton:190, gap: "small"});
      }
      else if (window.innerWidth >= 600){
        setSizeChar({ sizeHeader: 18, sizeWord:  14, sizeImage: 2.0, sizeMargin: 20, sizeButton:350, gap: "large"});
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  if (themeClassName === "dark-theme") {
    buttonType = "primary";
  } else if (themeClassName === "light-theme") {
    buttonType = "default";
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonKey = e.currentTarget.getAttribute('data-key');
    console.log('Button key:', buttonKey);
    window.location.href = buttonKey || '';
  };
  

  return (
    // <div className='home-button'>
      // <Flex vertical justify='center' align='center' style={{height:'100%'}}>
        <Flex gap={sizeChar.gap} vertical justify='center' align='center' style={{height:'100%'}}>
          <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/document" style={{width:sizeChar.sizeButton}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FileTextOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>
                <div style={{ fontSize: sizeChar.sizeHeader}}>Chat with your Documents</div>
                <div style={{ fontSize: sizeChar.sizeWord}}>(pdf, doc, pptx, txt)</div>
                {/* <div>Screen Width: {screenSize.width}px</div>
                <div><p>Screen Height: {screenSize.height}px</p></div> */}
              </div>
            </div>
          </Button>

          <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/structureFile" style={{width:sizeChar.sizeButton}}> 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FileExcelOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>
                <div style={{ fontSize: sizeChar.sizeHeader }}>Chat with Structure Documents</div>
                <div style={{ fontSize: sizeChar.sizeWord }}>(xlsx, json, SQL)</div>
              </div>  
            </div>
          </Button>

          <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/webpage" style={{width:sizeChar.sizeButton}}> 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GlobalOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>  
                <div style={{ fontSize: sizeChar.sizeHeader }}>Chat with Webpage</div>
              </div>   
            </div>
          </Button>

          <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/wineInfor" style={{width:sizeChar.sizeButton}}> 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CoffeeOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>   
                <div style={{ fontSize: sizeChar.sizeHeader }}>Chat with Wine Information</div>
              </div>
            </div>
          </Button>

          {/* <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/image" style={{width:sizeChar.sizeButton}}> 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PictureOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>
                <div style={{ fontSize: sizeChar.sizeHeader}}>Generate Image</div>
                <div className="custom-text" style={{ fontSize: sizeChar.sizeHeader }}>Developing</div>
              </div>
            </div>
          </Button> */}

          <div style={{ fontSize: sizeChar.sizeHeader, textAlign: 'center'}}>- or -</div>

          <Button type={buttonType} block size={'large'} onClick={handleButtonClick} data-key="/chatGPT" style={{width:sizeChar.sizeButton}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MessageOutlined style={{ marginRight: sizeChar.sizeMargin, transform: `scale(${sizeChar.sizeImage})` }} />
              <div>
                <div style={{ fontSize: sizeChar.sizeHeader }}>Continue with NOIS GPT</div>
                {/* <div className="custom-text">Developing</div> */}
              </div>
            </div>
          </Button>
        </Flex>
      // </Flex>
    // </div>
    
  )
}

export default HomeContent