import { Menu } from 'antd'
import { HomeOutlined, GlobalOutlined, PictureOutlined, FileExcelOutlined, FilePdfOutlined, MessageOutlined, CoffeeOutlined, YoutubeOutlined} from '@ant-design/icons'

interface MenuListProps {
    darkTheme: boolean;
    onClick: (key: string) => void;
    collapsed: boolean;
  }

  const MenuList: React.FC<MenuListProps> = ({ darkTheme, onClick, collapsed }) => {
    const handleMenuClick = (e: any) => {
        onClick(e.key);
      };

    const itemStyle: React.CSSProperties = collapsed ? {} : { whiteSpace: 'normal', height: 'auto' };


    return (
    <Menu theme={ darkTheme ? 'dark' : 'light'} 
    className='menu-bar-home' 
    onClick={handleMenuClick} 
    defaultSelectedKeys={[window.location.pathname]}>

        <Menu.Item key="/homeContent" icon={<HomeOutlined />}>
            Home
        </Menu.Item>

        <Menu.Item key="/chatGPT" icon={<MessageOutlined />}>
            NOIS GPT 
        </Menu.Item>

        <Menu.Item key="/document" style={itemStyle} icon={<FilePdfOutlined />}>
            {collapsed ? 'Document' : 'Chat with your Documents (pdf, doc, pptx, txt)'}
        </Menu.Item>

        <Menu.Item key="/structureFile" style={itemStyle} icon={<FileExcelOutlined />}>
            {collapsed ? 'Structure' : 'Chat with Structure Documents (xlsx)'}
        </Menu.Item>

        <Menu.Item key="/webpage" style={itemStyle} icon={<GlobalOutlined />}>
            {collapsed ? 'Webpage' : 'Chat with this Webpage'}
        </Menu.Item>

        <Menu.Item key="/wineInfor" style={itemStyle} icon={<CoffeeOutlined />}>
            {collapsed ? 'Wine' : 'Wine Information'}
        </Menu.Item>
{/* 
        <Menu.Item key="/image" style={itemStyle} icon={<PictureOutlined />}>
            {collapsed ? 'Images' : 'Generate Images'}
        </Menu.Item> */}

        <Menu.Item key="/demoVideo" style={itemStyle} icon={<YoutubeOutlined />}>
            {collapsed ? 'Video' : 'Demo Video'}
        </Menu.Item>
             
    </Menu>
  )
}

export default MenuList